import React from "react"
import { Helmet } from "react-helmet"
import Layout from "../components/layout/layout"
import Banner from "../components/Banner/Banner"
import * as ServicesStyles from "./services.module.css"
import MetaImage from "../images/forest-sun.jpg"
import TxtImg from "../components/TxtImg/TxtImg"
import Underline from "../components/Underline/Underline"
import Button from "../components/Button/Button"
import CTA from "../components/CTA/CTA"

export default function Services({ data }) {
    return (
        <Layout>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Counselling & sychotherapy Services | Paula Abbey Counselling & Psychotherapy</title>
                <meta name="title" content="Counselling & Psychotherapy Services | Paula Abbey Counselling & Psychotherapy" />
                <meta name="description" content="I have a particular interest in working with loss, although my work as a therapist, along with personal experience, has ignited a keen interest in the significant effect the food we eat has on our mental health." />

                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://paulaabbey.co.uk/services/" />
                <meta property="og:title" content="Counselling & Psychotherapy Services | Paula Abbey Counselling & Psychotherapy" />
                <meta property="og:description" content="I have a particular interest in working with loss, although my work as a therapist, along with personal experience, has ignited a keen interest in the significant effect the food we eat has on our mental health." />
                <meta property="og:image" content={MetaImage} />

                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:url" content="https://paulaabbey.co.uk/services/" />
                <meta name="twitter:title" content="Counselling & Psychotherapy Services | Paula Abbey Counselling & Psychotherapy" />
                <meta name="twitter:description" content="I have a particular interest in working with loss, although my work as a therapist, along with personal experience, has ignited a keen interest in the significant effect the food we eat has on our mental health." />
                <meta name="twitter:image" content={MetaImage} />
            </Helmet>
            <Banner image={data.background.childImageSharp.fluid}>
                <h1>Counselling and psychotherapy services.</h1>
            </Banner>
            <section>
                <TxtImg split="half" imgFirst={false} imgSrc={data.dandelion.childImageSharp.fluid} imgAlt="Dandelion">
                    <Underline>
                        <h2>My services</h2>
                    </Underline>
                    <p className={ServicesStyles.issues}>I have a particular interest in working with <span className={ServicesStyles.highlight}><strong>loss</strong></span>, be that through <span className={ServicesStyles.highlight}>bereavement</span>, <span className={ServicesStyles.highlight}>loss of health</span>, of <span className={ServicesStyles.highlight}>youth</span>, of a <span className={ServicesStyles.highlight}>job</span> or a <span className={ServicesStyles.highlight}>relationship</span>. Through my work at a local hospice, clients' individual and unique experience of <span className={ServicesStyles.highlight}>bereavement and loss</span> has included the exploration of issues such as <span className={ServicesStyles.highlight}>abusive</span> or <span className={ServicesStyles.highlight}>controlling relationships</span>, <span className={ServicesStyles.highlight}>childhood related issues</span>, <span className={ServicesStyles.highlight}>addiction</span>, <span className={ServicesStyles.ServicesStyles}>gender</span> and <span className={ServicesStyles.highlight}>sexuality issues</span>, <span className={ServicesStyles.highlight}>family dynamics</span>, <span className={ServicesStyles.highlight}>stress</span>, <span className={ServicesStyles.highlight}>anxiety</span>, <span className={ServicesStyles.highlight}>depression</span>, <span className={ServicesStyles.highlight}>suicidal thoughts</span> and more.</p>
                    <p>My work as a therapist, along with personal experience, has ignited a keen interest in the significant effect the food we eat has on our mental health. To understand the complexities of the topic more fully, I have undergone extensive training in eating disorders and disordered eating.</p>
                    <Button type="internal" colour="primary" to="/disordered-eating-and-eating-disorders/">Disordered eating & eating disorders</Button>
                </TxtImg>
            </section>
            <section style={{backgroundColor: 'var(--primary-50)'}}>
                <div className={ServicesStyles.text}>
                    <Underline>
                        <h2>Therapy your way</h2>
                    </Underline>
                    <p>When finding a counsellor, it's important that you feel comfortable. This extends beyond just which therapist is right for you but also how you access therapy.</p>
                    <p>You can choose for your sessions to be held:</p>
                    <ul>
                        <li>Face to Face</li>
                        <li>Online</li>
                        <li>Telephone</li>
                    </ul>
                    <Button type="internal" colour="primary" to="/therapy-your-way/">Find the best fit for you</Button>
                </div>
            </section>
            <section className={ServicesStyles.cta}>
                <CTA>
                    <h2>Make your appointment, today.</h2>
                    <Button type="internal" colour="primary" to="/appointments/">Get in touch</Button>
                </CTA>
            </section>
        </Layout>
    )
}

export const query = graphql`
    query {
        background: file(relativePath: {eq: "images/forest-sun.jpg"}) {
            childImageSharp {
                fluid(maxWidth: 2000, quality: 72) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        dandelion: file(relativePath: {eq: "images/dandelion.jpg"}) {
            childImageSharp {
                fluid(maxWidth: 800, quality: 72) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
    }
`