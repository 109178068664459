import React from "react"
import PropTypes from "prop-types"
import * as TxtImgStyles from "./TxtImg.module.css"
import Img from "gatsby-image"

function TxtImg(props) {
    return (
        <div className={TxtImgStyles[props.split]}>
            <div className={props.imgFirst ? `${TxtImgStyles.imgFirst}` : ``}>
                {props.children}
            </div>
            <div>
                <Img fluid={props.imgSrc} alt={props.imgAlt} />
            </div>
        </div>
    )
}

TxtImg.propTypes = {
    split: PropTypes.string.isRequired,
    imgFirst: PropTypes.bool.isRequired,
    imgSrc: PropTypes.object.isRequired,
    imgAlt: PropTypes.string.isRequired,
}

export default TxtImg